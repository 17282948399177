<template>
  <div class="col-start-2 col-span-2 slogan">
    Rendre le travail plus simple, plus<br />agréable et plus productif
  </div>
  <div class="col-start-2 col-end-4 search">
    <SearchBar @change="change"/>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SearchBar from "../public/SearchBar.vue";

export default defineComponent({
  name: "SloganSearchbar",
  components: {
    SearchBar,
  },
  methods: {
    change(text) {
      this.$emit('change', text)
    }
  }
});
</script>

<style>
.slogan {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 20px;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.53;
  letter-spacing: -1px;
  text-align: center;
  color: #363660;
}

.search {
  padding: 25px 0px 25px 0px;
}
</style>