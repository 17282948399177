<template>
  <div>
    <BulleDeco positionY="-180" />
  </div>
  <div class="pb-10 mh-300 container ml-auto mr-auto pl-5 pr-5 z-10 blob-fear">
    <SloganSearchbar @change="change" />
    <SousMenu
      v-if="sousMenuData.length > 0"
      :data="sousMenuData"
      @up="selectedMenu"
    />
    <div class="col-start-2 col-span-2 sous_menu gap-4 sous_menu_content">
      <div class="lds-ring" id="md" v-if="faq.length == 0">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <Exval v-if="faqs.length > 0" :data="faqs" />
      <!--router-view :name="selectedSousMenu.routeName" /-->
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import SloganSearchbar from "../components/b2b6/Slogan_searchbar.vue";
import SousMenu from "../components/b2b6/SousMenu.vue";
//import sousMenuData from "../components/b2b6/sousMenu.data.js";
import BulleDeco from "../components/public/B2b6.bulle_deco.vue";
import Exval from "../components/b2b6/sous_menu_content/OutilExval.vue";
import axios from "axios";

export default defineComponent({
  name: "B2b4",
  components: {
    SloganSearchbar,
    SousMenu,
    BulleDeco,
    Exval,
  },
  data() {
    return {
      selectedSousMenu: {},
      sousMenuData: [],
      faqs: [],
      faq: [],
    };
  },
  methods: {
    change(text) {
      if (text != "") {
        const d = this.faq.filter(function (item) {
          if (
            item.answer.search(text) > -1 ||
            item.question.search(text) > -1
          ) {
            return true;
          } else {
            return false;
          }
        });
        this.faqs = [...d];
      } else {
        this.faqs = [...this.faq];
      }
    },
    selectedMenu(selected) {
      if (selected != null) {
        const faqs = this.faq.filter(function (item) {
          return item.category == selected.label;
        });
        this.faqs = [...faqs];
      } else {
        this.faqs = this.faq;
      }
    },
  },
  mounted() {
    const self = this;
    axios({
      method: "GET",
      url: "https://api.exval.fr/api/v1/static-page/faq-categories",
    })
      .then(function (response) {
        const d = response.data.datas;
        const t = d.map(function (item) {
          return {
            label: item,
            active: false,
          };
        });
        self.sousMenuData = [...t];
      })
      .catch(function (err) {
        console.log(err);
      });
    axios({
      method: "GET",
      url: "https://api.exval.fr/api/v1/static-page/faq",
    })
      .then(function (response) {
        let d = response.data.datas;
        self.faqs = [...d];
        self.faq = [...d];
      })
      .catch(function (err) {
        console.log(err);
      });
  },
});
</script>

<style scoped>
#md {
  text-align: center;
}
.sous_menu_content {
  justify-content: center;
}
.hez {
  width: 100%;
  display: block;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-top: 0;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 8px;
  border: 2px solid #383366;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #383366 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.mh-300 {
  min-height: 300px;
}
.pb-10 {
  padding-bottom: 30px !important;
}
.CTA-Big-1 label {
  display: none;
}
.blob-fear {
  position: relative;
}
</style>