<template>
  <img class="w-5 h-5 mt-3.5 ml-5 absolute" src="../../assets/search.png" />
  <div class="flex bg-white border-2 border-gray-300 rounded p-0.5 pl-12">
    <input
      class="w-full"
      type="text"
      placeholder="Rechercher dans le centre d'assistance"
      style="outline: none"
      @change="change"
      @keyup="up"
      @keydown="down"
      v-model="search"
    />
    <button class="CTA-Big-1" style="visibility: hidden">
      <label class="label m-auto">Rechercher</label>
    </button>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  data() {
    return {
      search: "",
      typingTimer: null,
      doneTypingInterval: 500,
    };
  },
  methods: {
    change() {
      this.$emit("change", this.search);
    },
    up() {
      clearTimeout(this.typingTimer);
      this.typingTimer = setTimeout(this.change, this.doneTypingInterval);
    },
    down() {
      clearTimeout(this.typingTimer);
    },
  },
};
</script>

<style scoped>
input::placeholder {
  width: 258px;
  height: 16px;
  margin: 10px 387px 10px 10px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  text-align: left;
  color: #999999;
}
.CTA-Big-1 {
  width: 117px;
  height: 36px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: NaNpx;
  border-radius: 4px;
  background-color: #363660;
}
label {
  height: 20px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
</style>
